<template>
    <ion-page>
        <ion-header>
            <ion-toolbar
                style="--background: #03a9f4; color: white; --min-height: 36px"
                class="custom-toolbar"
            >
                <div class="row align-center">
                    <slot name="before">
                        <div class="shrink">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                                @click="$router.go(-1)"
                            />
                        </div>
                    </slot>
                    <slot name="center">
                        <div class="grow text-xs-center" :style="'opacity: 1'">
                            KÝ LỆNH CỐ ĐỊNH THÔNG TIN
                        </div>
                    </slot>
                    <slot name="after">
                        <div class="shrink" style="opacity: 0">
                            <DxButton
                                type="default"
                                icon="mdi mdi-magnify "
                                class="ml-1"
                            />
                        </div>
                    </slot>
                </div>
            </ion-toolbar>
        </ion-header>

        <ion-content :scroll-events="false" class="content-box">
            <div class="row mt-2 mb-2 px-2">
                <div class="xs12">
                    <div class="style-title">
                        <div class="mr-1">
                            <i class="mdi mdi-road-variant"></i>
                        </div>
                        <span class="font-medium">
                            {{ Get_TenTuyen }}
                        </span>
                    </div>
                    <div class="style-title">
                        <div class="mr-1">
                            <i class="mdi mdi-bus-stop"></i>
                        </div>
                        <span class="font-medium"> {{ Get_TenTuyen.split("-")[0] }}</span>
                    </div>
                </div>
            </div>

            <div class="row font-medium color-primary px-2 mb-1">
                Cài đặt thông tin dữ liệu nhà xe được phép chỉnh sửa khi sửa lệnh
            </div>
            <div
                class="row mb-2 px-2"
                v-for="(item, index) in DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh"
                :key="index"
            >
                <DxCheckBox
                    v-model="item.select"
                    :text="item.TenThuocTinh"
                    class="mr-3"
                />
            </div>

            <div
                class="row mb-2 px-2"
                v-if="
                    ChiTietCauHinhDoanhNghiepTheoMaCauHinh[
                        $t('MaCauHinhDoanhNghiep.SuDungQuyTrinhCanBoKiemTraXe')
                    ] == 'true'
                "
            >
                <DxCheckBox
                    v-model="TuDongKyLenhKhiCanBoXacNhanKiemTraXe"
                    text="Tự động ký lệnh khi cán bộ kiểm tra xe xác nhận"
                />
            </div>
            <DxValidationGroup ref="formValidation" style="padding-bottom: 50px">
                <div class="row mb-4 px-2">
                    <div class="xs12">
                        <DxSelectBox
                            v-model:value="Get_ChiTietKeHoach.IdNhaXe"
                            label="Nhà xe"
                            :data-source="DanhSachNhaXeSelect"
                            value-expr="IdNhaXe"
                            display-expr="TenNhaXe"
                            labelMode="floating"
                            styling-mode="underlined"
                            validationMessageMode="always"
                            :search-enabled="true"
                            :showClearButton="true"
                        ></DxSelectBox>
                    </div>
                </div>
                <div class="row mb-4 px-2">
                    <div class="xs12">
                        <div class="font-12 color-067 label-title">
                            Giờ xuất bến KH (*)
                        </div>
                        <DxDateBox
                            v-model="Get_ChiTietKeHoach.Not"
                            displayFormat="HH:mm"
                            :openOnFieldClick="true"
                            :use-mask-behavior="true"
                            dropDownButtonTemplate="customIcon"
                            validationMessageMode="always"
                            styling-mode="underlined"
                            pickerType="rollers"
                            type="time"
                        >
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                        </DxDateBox>
                    </div>
                </div>
                <div class="row mb-4 px-2">
                    <div class="xs12">
                        <div class="font-12 color-067 label-title">
                            Biển kiểm soát (*)
                        </div>
                        <DxSelectBox
                            :data-source="DSXeDuKien"
                            display-expr="BienKiemSoat"
                            value-expr="IdDnvtXe"
                            :search-enabled="true"
                            validationMessageMode="always"
                            styling-mode="underlined"
                            field-template="field"
                            v-model:value="Get_BienKS.IdDnvtXe"
                            :showClearButton="true"
                        >
                            <template #field="{ data }">
                                <DxTextBox
                                    :value="
                                        data
                                            ? data.BienKiemSoat +
                                              ` (${$Helper.Common.formatDate(
                                                  data.PhuHieu_NgayHetHan,
                                              )})`
                                            : ``
                                    "
                                    :class="
                                        data &&
                                        new Date(data.PhuHieu_NgayHetHan) <
                                            $Helper.Common.getEndDay(new Date())
                                            ? 'color-warning-han-phu-hieu'
                                            : ''
                                    "
                                    hint="Là hạn phù hiệu trên tuyến của xe"
                                />
                            </template>
                            <DxValidator ref="validateBienKiemSoat">
                                <DxRequiredRule
                                    message="Biển kiểm soát không được bỏ trống!"
                                />
                            </DxValidator>
                        </DxSelectBox>
                    </div>
                </div>
                <div class="row mb-4 px-2">
                    <div class="xs12">
                        <div class="font-12 color-067 label-title">
                            Lái xe tiếp nhận lệnh (*)
                        </div>
                        <DxSelectBox
                            :data-source="DSLaiXeDuKien"
                            v-model="Get_LaiXeTiepNhanLenh.IdDnvtLaiXe"
                            value-expr="IdDnvtLaiXe"
                            :display-expr="(x) => (x ? `${x.HoTen} (${x.GplxMaSo})` : ``)"
                            :searchEnabled="true"
                            validationMessageMode="always"
                            styling-mode="underlined"
                            :onItemClick="onChangeLaiXeTiepNhanLenh"
                            :showClearButton="true"
                            item-template="item"
                        >
                            <template #item="{ data }">
                                <div
                                    style="
                                        white-space: break-spaces;
                                        border-bottom: 1px solid #dadce0;
                                    "
                                >
                                    {{ data ? `${data.HoTen} (${data.GplxMaSo})` : "" }}
                                </div>
                            </template>
                            <DxValidator ref="validateLaiXeTiepNhanLenh">
                                <DxRequiredRule
                                    message="Lái xe tiếp nhận lệnh không được bỏ trống!"
                                />
                            </DxValidator>
                        </DxSelectBox>
                    </div>
                </div>
                <div class="row mb-4 px-2">
                    <div class="xs12">
                        <div class="font-12 color-067">Lái xe đi cùng</div>
                        <DxTagBox
                            :data-source="DSLaiXeDiCungDuKien"
                            :display-expr="(x) => (x ? `${x.HoTen} (${x.GplxMaSo})` : ``)"
                            value-expr="IdDnvtLaiXe"
                            :show-selection-controls="true"
                            :multiline="false"
                            :show-multi-tag-only="true"
                            :search-enabled="true"
                            :showDropDownButton="true"
                            styling-mode="underlined"
                            validationMessageMode="always"
                            :onValueChanged="onValueChangedLaiXe"
                            v-model:value="LaiXePhu"
                            :showClearButton="true"
                            item-template="item"
                        >
                            <template #item="{ data }">
                                <div
                                    style="
                                        white-space: break-spaces;
                                        border-bottom: 1px solid #dadce0;
                                    "
                                >
                                    {{ data ? `${data.HoTen} (${data.GplxMaSo})` : "" }}
                                </div>
                            </template>
                        </DxTagBox>
                    </div>
                </div>
                <div class="row mb-4 px-2">
                    <div class="xs12">
                        <div class="font-12 color-067 label-title">Phụ xe</div>
                        <DxTextBox
                            validationMessageMode="always"
                            styling-mode="underlined"
                            v-model="phuXe"
                            :showClearButton="true"
                        >
                            <DxValidator>
                                <DxStringLengthRule
                                    :max="48"
                                    message="Phụ xe không vượt quá 48 ký tự!"
                                />
                            </DxValidator>
                        </DxTextBox>
                    </div>
                </div>
                <div class="row mb-4 px-2">
                    <div class="xs12">
                        <div class="font-12 color-067 label-title">Từ ngày (*)</div>
                        <DxDateBox
                            displayFormat="dd/MM/yyyy"
                            :openOnFieldClick="true"
                            :use-mask-behavior="true"
                            dropDownButtonTemplate="customIcon"
                            validationMessageMode="always"
                            styling-mode="underlined"
                            pickerType="rollers"
                            type="date"
                            v-model="TuNgay"
                            :onValueChanged="WatchTuNgayThayDoi"
                        >
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                            <DxValidator ref="validateTuNgay">
                                <DxRequiredRule
                                    message="Ký từ ngày không được bỏ trống!"
                                />
                                <DxRangeRule
                                    :min="minDate"
                                    message="Ký từ ngày không được nhỏ hơn ngày hiện tại!"
                                />
                                <DxRangeRule
                                    :max="DenNgay"
                                    message="Ký từ ngày phải nhỏ hơn Ký đến ngày"
                                />
                            </DxValidator>
                        </DxDateBox>
                    </div>
                </div>
                <div class="row mb-3 px-2">
                    <div class="xs12">
                        <div class="font-12 color-067 label-title">Đến ngày (*)</div>
                        <DxDateBox
                            displayFormat="dd/MM/yyyy"
                            :openOnFieldClick="true"
                            :use-mask-behavior="true"
                            dropDownButtonTemplate="customIcon"
                            validationMessageMode="always"
                            styling-mode="underlined"
                            pickerType="rollers"
                            type="date"
                            v-model="DenNgay"
                            :onValueChanged="WatchDenNgayThayDoi"
                        >
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                            <DxValidator ref="validateDenNgay">
                                <DxRequiredRule
                                    message="Ký đến ngày không được bỏ trống!"
                                />
                                <DxRangeRule
                                    :max="maxDate"
                                    :message="$i18n.t('DefaultString.MaxDate')"
                                />
                                <DxCustomRule
                                    :validationCallback="validationTuNgayDenNgay"
                                    message="Khoảng ngày chọn phải nhỏ hơn 31!"
                                />
                            </DxValidator>
                        </DxDateBox>
                    </div>
                </div>
                <div class="row mb-2 px-2">
                    <span class="font-medium">Danh sách chuyến đi trong ngày </span>
                </div>
                <div class="row">
                    <DxList
                        :dataSource="chiTietKeHoach"
                        height="calc(100% - 8px)"
                        page-load-mode="nextButton"
                        :hoverStateEnabled="false"
                        :focusStateEnabled="false"
                        :activeStateEnabled="false"
                        no-data-text="Không có dữ liệu!"
                        class="list-lenh-style"
                        :show-selection-controls="true"
                        :selection-mode="selectionMode"
                        :select-all-mode="selectAllMode"
                        v-model:selected-item-keys="selectedItemKeys"
                    >
                        <template #item="{ data: item }">
                            <div>
                                <Frame :item="item" />
                            </div>
                            <!-- :key="`${componentKey}-1`" -->
                        </template>
                    </DxList>
                </div>
                <div class="row p-fixed">
                    <div class="xs12">
                        <div
                            class="row justify-space-between"
                            style="
                                border-top: 1px solid #dadce0;
                                padding: 0 10px;
                                height: 50px;
                                align-items: center;
                            "
                        >
                            <div class="text-xs-center">
                                <DxButton
                                    text="Hủy"
                                    type="danger"
                                    styling-mode="text"
                                    width="100%"
                                    class="color-danger"
                                    @click="$router.go(-1)"
                                />
                            </div>
                            <div class="text-xs-center">
                                <DxButton
                                    :text="'KÝ LỆNH (' + selectedItemKeys.length + ')'"
                                    type="default"
                                    styling-mode="contained"
                                    class="color-primary"
                                    @click="showPopupNhapMaBaoMat"
                                    :disabled="selectedItemKeys.length < 1"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </DxValidationGroup>
            <PopupVue
                height="auto"
                v-if="ifPopupMaBaoMat"
                v-model:dialog="PopupMaBaoMat"
                title="Nhập mã bảo mật"
                buttonTextRight="Xác nhận"
                buttonTextLeft="Hủy"
                @close="XacNhanKyLenh"
                classPopup="popup-padding-bottom-8 popup-padding-right-16 popup-padding-left-16"
                :key="`${componentKey}-3`"
            >
                <template #content>
                    <PopupMatMaKyLenhVue ref="popupMatMaLenh" />
                </template>
            </PopupVue>

            <PopupVue
                height="auto"
                v-if="ifPopupXacNhanNhanLenhSauKhiTaoLenh"
                v-model:dialog="PopupXacNhanNhanLenhSauKhiTaoLenh"
                title=""
                :closeOnOutsideClick="false"
                buttonTextLeft="Đóng"
                class="popup-padding-8"
                @close="XacNhanNhanLenhSauKhiTaoLenh"
                heightScrollView="auto"
            >
                <template #content>
                    <ThongBaoVue
                        :message="ParamThongBao.message"
                        :state="ParamThongBao.state"
                        :title="ParamThongBao.title"
                    />
                </template>
            </PopupVue>
        </ion-content>
    </ion-page>
</template>
<script>
import { IonPage, IonHeader, IonContent, IonToolbar } from "@ionic/vue";
import { onIonViewWillEnter } from "@ionic/vue";
import {
    DxButton,
    DxTextBox,
    DxSelectBox,
    DxValidationGroup,
    DxValidator,
    DxDateBox,
    DxTagBox,
    DxCheckBox,
} from "devextreme-vue";
import {
    DxRequiredRule,
    DxStringLengthRule,
    DxCustomRule,
    DxRangeRule,
} from "devextreme-vue/data-grid";
import DxList from "devextreme-vue/list";
import Frame from "./components/Frame.vue";
import PopupVue from "../../../components/_Common/Popup.vue";
import PopupMatMaKyLenhVue from "./components/PopupMatMaKyLenh.vue";
import ThongBaoVue from "../../../components/_Common/ThongBao.vue";
export default {
    components: {
        DxButton,
        IonPage,
        IonHeader,
        IonContent,
        IonToolbar,
        DxTextBox,
        DxSelectBox,
        DxRequiredRule,
        DxStringLengthRule,
        DxCustomRule,
        DxValidationGroup,
        DxValidator,
        DxDateBox,
        DxList,
        Frame,
        DxTagBox,
        PopupVue,
        PopupMatMaKyLenhVue,
        DxRangeRule,
        onIonViewWillEnter,
        DxCheckBox,
        ThongBaoVue,
    },
    data() {
        return {
            chiTietKeHoach: [],
            DSLaiXeDuKien: [],
            DSLaiXeDiCungDuKien: [],
            DSXeDuKien: [],
            TuNgay: new Date(),
            DenNgay: new Date(),
            ifPopupMaBaoMat: false,
            PopupMaBaoMat: false,
            ifPopupXacNhanNhanLenhSauKhiTaoLenh: false,
            PopupXacNhanNhanLenhSauKhiTaoLenh: false,
            ParamThongBao: {
                state: "Success",
                title: "Thông báo!",
                message: "Vui lòng kiểm tra lại",
            },
            selectedItemKeys: [],
            maxItems: 2,
            LaiXePhu: [],
            phuXe: "",
            selectionMode: "all",
            selectAllMode: "allPages",
            Get_LaiXeTiepNhanLenh: {
                IdDnvtLaiXe: null,
            },
            TuDongKyLenhKhiCanBoXacNhanKiemTraXe: true,
        };
    },
    computed: {
        Get_ChiTietKeHoach() {
            return this.$store.state.DVVTLenh.ChiTietKeHoach || {};
        },
        Get_DanhSachLaiXeDuKien() {
            return this.$store.state.DVVTLenh.danhSachLaiXe || [];
        },
        Get_LaiXe() {
            return this.$store.state.DVVTLenh.laiXeTiepNhanLenh || [];
        },
        DanhSachLaiXePhu() {
            let tmpArr = this.Get_DanhSachLaiXeDuKien
                ? this.Get_DanhSachLaiXeDuKien.filter(
                      (e) => e.IdDnvtLaiXe != this.Get_LaiXeTiepNhanLenh.IdDnvtLaiXe,
                  )
                : [];
            return tmpArr;
        },
        DanhSachLaiXeDiCung() {
            let arrTemp = [];
            for (let i = 1; i < this.Get_LaiXe.length; i++) {
                arrTemp.push(this.Get_LaiXe[i].IdDnvtLaiXe);
            }
            return arrTemp;
        },
        Get_BienKS: {
            get() {
                return this.$store.state.DVVTLenh.bienKiemSoat || {};
            },
            set(data) {
                this.$store.commit("DVVTLenh/SET_BIEN_KIEM_SOAT", data);
            },
        },
        Get_HoTenPhuXe() {
            return this.$store.state.DVVTLenh.hoTenPhuXe || "";
        },
        minDate() {
            return this.$Helper.getStartDay(new Date());
        },
        maxDate() {
            return this.$store.state.DVVTLenh.maxDate;
        },
        Get_NgayDuong() {
            return this.$store.state.DVVTLenh.NgayDuong;
        },
        Get_TenTuyen() {
            return this.$store.state.DVVTLenh.TenTuyen;
        },
        ChiTietCauHinhDoanhNghiepTheoMaCauHinh() {
            return this.$store.state.DVVTLenh.ChiTietCauHinhDoanhNghiepTheoMaCauHinh;
        },
        DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh: {
            get() {
                return (
                    this.$store.state.DVVTLenh.DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh ||
                    []
                );
            },
            set(data) {
                this.$store.commit("DVVTLenh/Set", {
                    key: "DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh",
                    data: data,
                });
            },
        },
        DanhSachNhaXeSelect() {
            return this.$store.state.ThongTinLaiXe.DanhSachNhaXeSelect;
        },
        DanhSachLenhCuaLaiXeSauKhiKyLenh: {
            get() {
                return this.$store.state.DVVTLenh.DanhSachLenhCuaLaiXeSauKhiKyLenh || [];
            },
            set(data) {
                this.$store.commit("DVVTLenh/Set", {
                    key: "DanhSachLenhCuaLaiXeSauKhiKyLenh",
                    data: data,
                });
            },
        },
    },
    watch: {
        Get_LaiXeTiepNhanLenh: {
            immediate: true,
            deep: true,
            handler: function () {
                this.LaiXePhu = this.LaiXePhu.filter(
                    (e) => e != this.Get_LaiXeTiepNhanLenh.IdDnvtLaiXe,
                );
                this.DSLaiXeDiCungDuKien = this.DSLaiXeDuKien.filter(
                    (e) => e.IdDnvtLaiXe != this.Get_LaiXeTiepNhanLenh.IdDnvtLaiXe,
                );
                this.LaiXePhu = this.LaiXePhu.filter((lx) =>
                    this.DSLaiXeDiCungDuKien.some((e) => lx == e.IdDnvtLaiXe),
                );
            },
        },
        PopupXacNhanNhanLenhSauKhiTaoLenh() {
            if (!this.PopupXacNhanNhanLenhSauKhiTaoLenh) {
                this.NgatDOMPopup("ifPopupXacNhanNhanLenhSauKhiTaoLenh");
            }
        },
        PopupMaBaoMat() {
            if (!this.PopupMaBaoMat) {
                this.NgatDOMPopup("ifPopupMaBaoMat");
            }
        },
    },
    methods: {
        showPopup(name) {
            this["if" + name] = true;
            this[name] = true;
        },
        NgatDOMPopup(key) {
            setTimeout(() => {
                this[key] = false;
            }, 300);
        },
        onChangeLaiXeTiepNhanLenh(e) {
            if (e.itemData) {
                this.LaiXePhu = this.LaiXePhu.filter(
                    (e) => e != this.Get_LaiXeTiepNhanLenh.IdDnvtLaiXe,
                );
                this.DSLaiXeDiCungDuKien = this.DSLaiXeDuKien.filter(
                    (e) => e.IdDnvtLaiXe != this.Get_LaiXeTiepNhanLenh.IdDnvtLaiXe,
                );
            }
        },
        WatchTuNgayThayDoi(e) {
            let isCheck = this.CheckThayDoiThoiGian();
            if (isCheck) {
                this.GetChiTietKeHoach();
            }
        },
        WatchDenNgayThayDoi(e) {
            let isCheck = this.CheckThayDoiThoiGian();
            if (isCheck) {
                this.GetChiTietKeHoach();
            }
        },
        CheckThayDoiThoiGian() {
            // if (!this.$refs.validateTuNgay || !this.$refs.validateDenNgay) return false;
            // let validateTuNgay = this.$refs.validateTuNgay.instance.validate();
            // let validateDenNgay = this.$refs.validateDenNgay.instance.validate();
            // if (validateTuNgay.isValid && validateDenNgay.isValid) {
            //     return true;
            // }
            // return false;
            this.$refs.validateTuNgay.instance.validate();
            this.$refs.validateDenNgay.instance.validate();
            if (
                !!this.TuNgay &&
                !!this.DenNgay &&
                this.TuNgay <= this.DenNgay &&
                this.DenNgay < this.maxDate &&
                this.TuNgay >= this.minDate
            ) {
                return true;
            }
            return false;
        },
        async LayCauHinhChinhSuaKhiKyLenhTheoTuyenVaBKS() {
            if (
                !this.KyLenhTuyChon_ThongTinChung.ID_DnvtTuyen ||
                !this.KyLenhTuyChon_ThongTinChung.ID_DnvtXe
            ) {
                return;
            }
            let data = await this.LayCauHinhChinhSuaKhiKyLenh({
                IdDnvtTuyen: this.KyLenhTuyChon_ThongTinChung.ID_DnvtTuyen,
                IdDnvtXe: this.KyLenhTuyChon_ThongTinChung.ID_DnvtXe,
                IdNhaXe: this.KyLenhTuyChon_ThongTinChung.IdNhaXe,
            });
            if (data.length > 0) {
                this.DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh = this.DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh.map(
                    (e) => {
                        let check = data.some((dt) => dt.MaThuocTinh == e.MaThuocTinh);
                        e.select = check;
                        return e;
                    },
                );
            }
        },
        async LayTatCaCauHinhChinhSuaKhiKyLenh() {
            let data = await this.LayCauHinhChinhSuaKhiKyLenh({
                IdDnvtTuyen: null,
                IdDnvtXe: null,
                IdNhaXe: null,
            });
            if (data.length > 0) {
                this.DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh = data.map((e) => {
                    e.select = true;
                    return e;
                });
            }
        },
        async LayCauHinhChinhSuaKhiKyLenh({ IdDnvtTuyen, IdDnvtXe, IdNhaXe }) {
            try {
                let rs = await this.$store.dispatch(
                    "DVVTLenh/LayCauHinhChinhSuaKhiKyLenh",
                    {
                        IdDnvtTuyen: IdDnvtTuyen || null,
                        IdDnvtXe: IdDnvtXe || null,
                        IdNhaXe: IdNhaXe || null,
                    },
                );

                if (!rs.status) {
                    this.$Helper.ThongBaoToast(
                        "error",
                        "Lấy cấu hình chỉnh sửa khi ký lệnh thất bại! " + rs.message,
                    );
                }
                return rs.data || [];
            } catch (error) {
                console.log(error);
            }
        },
        onValueChangedLaiXe(e) {
            if (e.value?.length > this.maxItems) {
                const newValue = e.value.slice(0, this.maxItems);
                e.component.option("value", newValue);
            }
        },
        validationTuNgayDenNgay() {
            let TuNgay = new Date(this.$Helper.getStartDay(this.TuNgay)).getTime();
            let DenNgay = new Date(this.$Helper.getEndDay(this.DenNgay)).getTime();

            let minusResult = (DenNgay - TuNgay) / (1000 * 60 * 60 * 24);
            if (minusResult > 31) return false;
            return true;
        },
        showPopupNhapMaBaoMat() {
            let valid = this.$refs.formValidation.instance.validate();

            if (!valid.isValid) {
                this.$Helper.Common.autoFocusError(valid);
                return;
            } else {
                this.ifPopupMaBaoMat = true;
                this.PopupMaBaoMat = true;
            }
        },
        async GetDanhSachXeDuKien() {
            try {
                // let key = "ID_KeHoach";
                // let query = {
                //     TuNgay: this.$Helper.getStartDay(this.filter.TuNgay),
                //     DenNgay: this.$Helper.getStartDay(this.filter.DenNgay),
                //     IdDnvtTuyen: this.filter.TuyenVanChuyen,
                // };
                let IdKeHoach = this.$route.query.IdKeHoach || "";
                this.DSXeDuKien = await this.$store.dispatch(
                    "DVVTLenh/Get_DanhSachXeDuKienTheoKeHoach",
                    {
                        IdKeHoach: IdKeHoach,
                    },
                );
            } catch (ex) {
                console.log("🚀 ex", ex);
            }
        },

        async GetDanhSachLaiXeDuKien() {
            try {
                // let key = "ID_KeHoach";
                // let query = {
                //     TuNgay: this.$Helper.getStartDay(this.filter.TuNgay),
                //     DenNgay: this.$Helper.getStartDay(this.filter.DenNgay),
                //     IdDnvtTuyen: this.filter.TuyenVanChuyen,
                // };
                let IdKeHoach = this.$route.query.IdKeHoach || "";
                this.DSLaiXeDuKien = await this.$store.dispatch(
                    "DVVTLenh/Get_DanhSachLaiXeDuKienTheoKeHoach",
                    {
                        IdKeHoach: IdKeHoach,
                    },
                );

                this.Get_LaiXeTiepNhanLenh = {};

                let itemLaiXeTiepNhanLenh =
                    this.Get_LaiXe.length && this.Get_LaiXe.length > 0
                        ? this.Get_LaiXe[0]
                        : {};

                if (!itemLaiXeTiepNhanLenh.IdDnvtLaiXe) {
                    return;
                }

                let check = this.DSLaiXeDuKien.some(
                    (e) => e.IdDnvtLaiXe == itemLaiXeTiepNhanLenh.IdDnvtLaiXe,
                );
                if (!check) {
                    this.$Helper.ThongBaoToast(
                        "warning",
                        "Không tìm thấy thông tin lái xe tiếp nhận lệnh theo kế hoạch!",
                    );
                }
                this.Get_LaiXeTiepNhanLenh = check ? itemLaiXeTiepNhanLenh : {};
            } catch (ex) {
                console.log("🚀 ex", ex);
            } finally {
                if (!this.Get_LaiXeTiepNhanLenh.IdDnvtLaiXe) {
                    let resetLaiXeTiepNhanLenh = setInterval(() => {
                        if (this.$refs.validateLaiXeTiepNhanLenh) {
                            this.$refs.validateLaiXeTiepNhanLenh.instance.reset();
                            clearInterval(resetLaiXeTiepNhanLenh);
                        }
                    }, 100);
                }
            }
        },

        async GetChiTietKeHoach(changeKeHoach) {
            this.$startLoading;
            try {
                this.chiTietKeHoach = [];
                this.selectedItemKeys = [];
                let query = {
                    TuNgay: this.TuNgay,
                    DenNgay: this.DenNgay,
                    IdKeHoach: this.$route.query.IdKeHoach,
                    changeKeHoach: changeKeHoach,
                };

                let result = await this.$store.dispatch("DVVTLenh/ChiTietKeHoach", {
                    query,
                });
                this.chiTietKeHoach = result;
                this.$stopLoading;
            } catch (ex) {
                console.log("🚀 ex", ex);
                this.$stopLoading;
            }
        },
        async XacNhanKyLenh(param) {
            if (!param) {
                this.PopupMaBaoMat = false;
                return;
            }
            try {
                //Lấy thông tin lọc dữ liệu
                let valid = this.$refs.formValidation.instance.validate();

                if (!valid.isValid) {
                    this.$Helper.Common.autoFocusError(valid);
                    return;
                } else {
                    let validMK = this.$refs.popupMatMaLenh.$refs.validateMaBaoMat.instance.validate();
                    if (!validMK.isValid) {
                        return this.$Helper.Common.autoFocusError(validMK);
                    } else {
                        let query = [];
                        this.selectedItemKeys.forEach((item) => {
                            let IdBenXeXuatPhat = this.Get_ChiTietKeHoach.IdBenXeDi;
                            let IdDnvtTuyen = this.Get_ChiTietKeHoach.IdDnvtTuyen;
                            let IdNhaXe = this.Get_ChiTietKeHoach.IdNhaXe;
                            let IdDnvtXe = this.Get_BienKS.IdDnvtXe;
                            let IdKeHoach = this.$route.query.IdKeHoach;
                            let LaiXeChinh_IdDnvtLaixe = this.Get_LaiXeTiepNhanLenh
                                .IdDnvtLaiXe;
                            let LaiXeDiCung_IdDnvtLaixes = this.LaiXePhu;
                            let NgayXuatBen = this.$Helper.getStartDay(item.NgayDuong);
                            let Not = this.$Helper.getUTCDateFromTimeString(
                                this.Get_ChiTietKeHoach.Not,
                            );
                            let maBaoMat = this.$refs.popupMatMaLenh.MaBaoMat;
                            let kyLenhKhiCanBoXacNhanKiemTraXe =
                                this.ChiTietCauHinhDoanhNghiepTheoMaCauHinh[
                                    this.$t(
                                        "MaCauHinhDoanhNghiep.SuDungQuyTrinhCanBoKiemTraXe",
                                    )
                                ] == "true"
                                    ? this.TuDongKyLenhKhiCanBoXacNhanKiemTraXe
                                    : false;
                            let PhuXes = this.phuXe.split(",");
                            let PhanQuyenDuLieuChinhSua = this.DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh.filter(
                                (e) => e.select,
                            ).map((e) => e.MaThuocTinh);
                            query.push({
                                IdBenXeXuatPhat,
                                IdDnvtTuyen,
                                IdNhaXe,
                                IdDnvtXe,
                                IdKeHoach,
                                LaiXeChinh_IdDnvtLaixe,
                                LaiXeDiCung_IdDnvtLaixes,
                                NgayXuatBen,
                                Not,
                                maBaoMat,
                                PhuXes,
                                kyLenhKhiCanBoXacNhanKiemTraXe,
                                PhanQuyenDuLieuChinhSua,
                            });
                        });
                        this.$startLoading;
                        try {
                            let rs = await this.$store.dispatch(
                                "DVVTLenh/TaoVaKyLenh",
                                query,
                            );

                            if (rs.errorCode == 0 && rs.status == true) {
                                if (rs.data && rs.data.length > 0) {
                                    let thongBaoTonTaiLenh = "";
                                    rs.data.forEach((e, i) => {
                                        if (i == 0) {
                                            thongBaoTonTaiLenh += `- Thông tin lỗi: \n`;
                                        }
                                        thongBaoTonTaiLenh += `+ ${e.ThongTinLoi}\n`;
                                    });
                                    this.$Helper.ThongBaoPopup({
                                        type: "Warning",
                                        message_title: "Thông tin kết quả ký lệnh!",
                                        message: `${thongBaoTonTaiLenh}`,
                                    });
                                    return;
                                }
                                // this.$Helper.ThongBaoToast(
                                //     "success",
                                //     "Ký lệnh thành công!",
                                // );
                                // this.$router.push("/DNVT-Quan-Ly-Lenh");
                                this.ParamThongBao.title = "Tạo lệnh thành công!";
                                this.ParamThongBao.message =
                                    "Bạn có muốn tiếp tục nhận lệnh không?";
                                this.ParamThongBao.state = "Success";
                                this.showPopup("PopupXacNhanNhanLenhSauKhiTaoLenh");
                            } else {
                                this.$Helper.ThongBaoToast("error", `${rs.message}`);
                            }
                            this.PopupMaBaoMat = false;
                        } catch (err) {
                            console.log(err);
                            this.$Helper.ThongBaoToast("error", "Ký lệnh thất bại!");
                        } finally {
                            this.$stopLoading;
                        }
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
        XacNhanNhanLenhSauKhiTaoLenh(bool) {
            if (bool) {
                this.LayThongTinLenhSauKhiTaoLenh();
            } else {
                this.$router.go(-1);
            }
        },
        async LayThongTinLenhSauKhiTaoLenh() {
            try {
                this.DanhSachLenhCuaLaiXeSauKhiKyLenh = [];
                let DanhSachNgayKyLenh = this.selectedItemKeys.map((e) => e.NgayDuong);
                DanhSachNgayKyLenh.sort((a, b) => a - b);
                for (let index = 0; index < DanhSachNgayKyLenh.length; index++) {
                    if (this.DanhSachLenhCuaLaiXeSauKhiKyLenh.length > 5) {
                        break;
                    }
                    let item = DanhSachNgayKyLenh[index];
                    let tmpDanhSachLenhLaiXe = await this.LayLenhCuaLaiXeSauKhiKyLenh(
                        item,
                    );
                    this.DanhSachLenhCuaLaiXeSauKhiKyLenh.push(...tmpDanhSachLenhLaiXe);
                }
                this.$router.go(-1);
                setTimeout(() => {
                    this.$router.push("/Nhan-Lenh-Sau-Khi-Tao-Lenh");
                }, 100);
            } catch (error) {
                console.log("🚀 ~ error:", error);
            }
        },
        async LayLenhCuaLaiXeSauKhiKyLenh(NgayXuatBen) {
            try {
                let query = {
                    timKiem: null,
                    idLuongTuyen: null,
                    ngayXuatBenKeHoach: this.$Helper.Common.getEndDay(NgayXuatBen),
                    danhSachGioXuatBen: [],
                };
                let rs = await this.$store.dispatch(
                    "DVVTLenh/LayLenhCuaLaiXeSauKhiKyLenh",
                    {
                        query,
                    },
                );
                return rs;
            } catch (error) {
                console.log(
                    "🚀 ~ file: index.vue:789 ~ LayLenhCuaLaiXeSauKhiKyLenh ~ error:",
                    error,
                );
            }
        },
        async LayDanhSachNhaXe() {
            try {
                let rs = await this.$store.dispatch("ThongTinLaiXe/LayDanhSachNhaXe");

                if (!rs.status) {
                    this.$Helper.ThongBaoToast(
                        "error",
                        `Lấy danh sách nhà xe thất bại! ${rs.message}`,
                    );
                }
            } catch (error) {
                console.log(error);
            }
        },
    },

    created() {
        onIonViewWillEnter(() => {
            this.$startLoading;
            if (
                this.$Helper.Common.getEndDay(this.Get_NgayDuong) >
                this.$Helper.Common.getStartDay(new Date())
            ) {
                this.TuNgay = this.$Helper.getStartDay(this.Get_NgayDuong);
                this.DenNgay = this.$Helper.getEndDay(this.Get_NgayDuong);
            }
            this.LayDanhSachNhaXe();
            this.LayTatCaCauHinhChinhSuaKhiKyLenh();
            this.GetDanhSachXeDuKien();
            this.GetDanhSachLaiXeDuKien();
            this.GetChiTietKeHoach(true);
            this.LaiXePhu = this.DanhSachLaiXeDiCung;
            this.phuXe = this.Get_HoTenPhuXe;
            this.$stopLoading;
            let resetMaBaoMat = setInterval(() => {
                if (this.$refs.popupMatMaLenh) {
                    this.$refs.popupMatMaLenh.resetMaBaoMat();
                    clearInterval(resetMaBaoMat);
                }
            }, 100);

            if (!this.Get_BienKS.IdDnvtXe) {
                let resetBienKS = setInterval(() => {
                    if (this.$refs.validateBienKiemSoat) {
                        this.$refs.validateBienKiemSoat.instance.reset();
                        clearInterval(resetBienKS);
                    }
                }, 100);
            }
        });
    },
    mounted() {},
};
</script>
<style lang="css">
.style-title {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.2;
}
.p-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
}
</style>
<style scoped>
:deep(.dx-editor-underlined.dx-texteditor-with-floating-label.dx-state-focused
        .dx-texteditor-label
        .dx-label
        span),
:deep(.dx-editor-underlined.dx-texteditor-with-floating-label:not(.dx-texteditor-empty)
        .dx-texteditor-label
        .dx-label
        span) {
    font-size: 12px;
}
:deep(.dx-editor-underlined.dx-texteditor-with-floating-label.dx-state-focused
        .dx-texteditor-label
        .dx-label
        span),
:deep(.dx-editor-underlined.dx-texteditor-with-floating-label:not(.dx-texteditor-empty)
        .dx-texteditor-label) {
    line-height: 16px;
    height: 16px;
}
:deep(.dx-editor-underlined.dx-texteditor-with-floating-label.dx-texteditor-empty
        .dx-texteditor-label) {
    height: 23px;
    line-height: 23px;
}
.label-title {
    position: relative;
    top: 4px;
}
</style>
